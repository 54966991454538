import { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ShoppingCart } from "react-feather";
import MobileNav from "./MobileNav/MobileNav";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router";
import { getCookie, redirectToLogin, setCookie } from "../../utils";
import { supportLogout } from "../../redux/reducers/supportSlice";

const Nav = (props) => {
  const { t } = useTranslation();
  const [sidebar, setSidebar] = useState(false);
  const cartItems = useSelector((state) => state.cart.items);
  const history = useHistory();
  const location = useLocation();

  const { env, support } = useSelector((state) => state);

  const [isLoggedIn, setIsLoggedIn] = useState(
    getCookie("access-token") || getCookie("auth-id")
  );

  const [return_url, set_return_url] = useState();

  useEffect(() => {
    history.listen((location) => {
      setIsLoggedIn(getCookie("access-token") || getCookie("auth-id"));
    });
  }, []);

  const handleLogin = () => {
    const regex = new RegExp(/^\/checkout/);
    if (regex.test(location.pathname)) {
      setCookie("redirect", "cart");
    }
    redirectToLogin();
  };

  const check = () => {
    cartItems.length > 0 ? history.push("/cart") : history.push("/hosting");
  };

  const toProfile = () => {
    window.location.href = `${env.ADMIN_URL}/enterprise`;
  };

  const dispatch = useDispatch();

  const exit_support = () => {
    dispatch(supportLogout());
    setCookie("support", null, -1);
    toProfile();
  };

  return (
    <Fragment>
      <div className="dark-top-bar">
        <div className="container">
          <div className="col-12">
            <div className="clearfix">
              <ul>
                <li className="left">
                  <a href="#">support@leanstack.co</a>
                </li>
                {/* <li className="right">
                  <Link onClick={() => check()} to="#">
                    <i>
                      <ShoppingCart size="14" />
                    </i>
                    &nbsp;&nbsp;&nbsp;{t("nav.cart")}
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {support.type && (
        <div className="dark-top-bar" style={{ backgroundColor: "#009A4E" }}>
          <div className="container">
            <div className="col-12">
              <div className="clearfix">
                <p className="fl-left text text-light">
                  {t("support_text")} {support?.name}{" "}
                  {`(${t(support.type)}-id: ${
                    support.user_id || support.organisation_id
                  })`}
                </p>
                <button
                  onClick={() => exit_support()}
                  className="btn btn-light fl-right"
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="header home-hd">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-xs-6">
              <a href="/">
                <img
                  src={`${process.env.PUBLIC_URL}/img/blue-logo-top.svg`}
                  alt=""
                />
              </a>
            </div>

            <div className="col-sm-9 col-xs-6">
              <ul className="nav-right">
                {isLoggedIn ? (
                  <li>
                    <NavLink
                      to="#"
                      className="blue-link"
                      onClick={() => toProfile()}
                    >
                      My Account
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <Link
                      to="#"
                      onClick={() => handleLogin(return_url)}
                      className="blue-link"
                    >
                      {t("login.button")}
                    </Link>
                  </li>
                )}
              </ul>

              <div className="middle-nav">
              {/*  <NavLink to="/domains">{t("nav.domains")}</NavLink> */}
                {/* <NavLink to="/hosting">{t("nav.hosting")}</NavLink> */}
                {/* <NavLink to="#">{t("nav.vps")}</NavLink>
                <NavLink to="#">{t("nav.email")}</NavLink> */}
                <a href="https://leanstack.co/google-workspace/" target="_blank">Google Workspace</a>
                <a href="https://blog.leanstack.co" target="_blank">
                  {t("nav.blog")}
                </a>
              </div>

              <div
                id="nav-icon1"
                className={sidebar ? "open" : ""}
                onClick={() => setSidebar(!sidebar)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileNav />
    </Fragment>
  );
};

export default withRouter(Nav);
